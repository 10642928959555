<template>
  <base-view title="Listados" icon="layers">
    <div>
      <check-authorization :requiresAuthorizations="['listado propiedades pagadas']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Listado de propiedades pagadas/no pagadas
          </b-card-header>

          <b-card-body class="p-2">
            <Propiedades/>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import Propiedades from '@/components/Operaciones/Listados/Propiedades'

export default {
  name: 'ListadoPropiedadesMainView',

  components: {
    Propiedades
  }
}
</script>

<style scoped>

</style>
