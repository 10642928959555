<template>
  <div>
    <b-form-group class="ml-3 mt-2" label="Enviaron factura">
      <b-form-radio v-model="enviaron_factura" name="some-radios" value="1">SI</b-form-radio>
      <b-form-radio v-model="enviaron_factura" name="some-radios" value="0">NO</b-form-radio>
    </b-form-group>
    <div v-if="isThereData">
      <b-pagination size="sm" align="fill"
        v-model="currentPage"
        :total-rows="getTotalItems"
        :per-page="perPage"
        :disabled="!getAvailableList"
        aria-controls="inversionistas-table"
      ></b-pagination>
    </div>

    <b-table
      id="propiedadesTable"
      v-if="isThereData"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      samll
      :per-page="perPage"
      :current-page="currentPage">
      <template #cell(tipo_persona)="row">
        <b-badge variant="secondary">{{ row.item.tipo_persona }}</b-badge>
      </template>
      <template #cell(status)="row">
        <b-badge v-if="!row.item.status" variant="danger">-</b-badge>
        <b-badge v-else variant="success">{{ row.item.status }}</b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const API_MODULE = 'listadoModule'

export default {
  name: 'Retenciones',

  data () {
    return {
      fields: [
        { key: 'identificacion', label: 'Identificación', sortable: true },
        { key: 'rfc', label: 'RFC' },
        { key: 'tipo_persona', label: 'Tipo de persona' },
        { key: 'numero', label: '# departamento' },
        { key: 'num_catastral', label: 'Número catastral' },
        { key: 'cuenta_predial', label: 'Cuenta predial' },
        { key: 'renta_actual', label: 'Renta actual' },
        { key: 'status', label: 'Estado' }
      ],

      perPage: 10,

      currentPage: 1,

      enviaron_factura: null
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ])
  },

  created () {
    // reset the module state
    this.$store.dispatch(`${API_MODULE}/resetState`)
  },

  watch: {
    enviaron_factura: {
      async handler (value) {
        if (value) {
          await this.getRetenciones()
        }
      }
    }
  },

  methods: {
    async getRetenciones () {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getPropiedadesPagadas`, {
        enviaron_factura: this.enviaron_factura
      })

      if (error) this.$notify({ error, message }, 'Retenciones')
    }
  }
}
</script>
